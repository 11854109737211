<template>
  <TransitionRoot v-loading="loading" as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="none">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Edit User {{ state.user.firstName }}
                      {{ state.user.lastName }}
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-gray-500"
                        @click="onClose"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative mt-6 flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <form class="flex h-full flex-col bg-white">
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="px-4 sm:px-6">
                        <div class="mb-4">
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            First Name
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="user-first-name"
                              id="user-first-name"
                              v-model="state.user.firstName"
                              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div class="mb-4">
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Middle Name
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="organisation-name"
                              id="organisation-name"
                              v-model="state.user.middleName"
                              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div class="mb-4">
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Last Name
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="organisation-name"
                              id="organisation-name"
                              v-model="state.user.lastName"
                              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div class="mb-4">
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Roles
                          </label>
                          <div class="mt-1">
                            <el-select
                              v-model="state.role"
                              placeholder="Select Role"
                              multiple
                              collapse-tags
                              style="width: 100%"
                              @change="onRoleChange"
                            >
                              <el-option
                                v-for="item in state.roles"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                        @click="onClose"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        @click="onSubmit"
                        class="focus:outline-none ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import useRolesRepository from "@/composable/useRolesRepository";
import useUsersRepository from "@/composable/useUsersRepository";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const open = ref(false);

    const { loading, roles, getRoles } = useRolesRepository();
    const { updateUser } = useUsersRepository();

    const state = reactive({
      user: {
        firstName: props.user.Parent.FirstName,
        lastName: props.user.Parent.LastName,
        middleName: props.user.Parent.MiddleName,
      },
      role: props.user.OrganizationRoles.map((r) => r.Name),
      roles: roles,
    });

    const onClose = () => {
      emit("onClose", true);
    };

    const onSubmit = () => {
      console.log(state.role);

      updateUser({
        id: props.user.Id,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        middleName: state.user.middleName,
        systemRoles: state.role,
      });
    };

    const onRoleChange = (value) => {
      console.log(value);
    };

    onMounted(() => {
      getRoles();
    });

    return {
      open,
      state,
      roles,
      loading,
      onClose,
      onSubmit,
      onRoleChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>
