<template>
  <slideover title="Invite User" size="lg">
    <!-- Replace with your content -->
    <div class="flex flex-1 flex-col justify-between">
      <div class="px-4 sm:px-6">
        <div class="space-y-6 pt-6 pb-5">
          <div class="flex">
            <el-select
              v-model="state.role"
              clearable
              placeholder="Select Role"
              style="width: 220px"
            >
              <el-option
                v-for="item in roles"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
            <span class="ml-3">
              <input
                type="email"
                name="Email"
                id="Email"
                v-model="state.name"
                placeholder="Type email"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
              />
            </span>

            <span class="ml-3">
              <button
                type="button"
                @click="onInvite"
                class="focus:outline-none focus:ring-light-blue-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                <PaperAirplaneIcon class="mr-1.5 h-5 w-5 text-gray-400" />
                <span class="ml-1">Invite</span>
              </button>
            </span>
          </div>
        </div>
        <div class="flex justify-center" v-if="invites.length === 0">
          <el-empty
            description="No users invited, just this empty box"
          ></el-empty>
        </div>

        <p class="pt-2" v-if="invites.length > 0">
          List of invitees ({{ invites.length }})
        </p>
        <invitees :invitees="invites" @onSubmit="removeInvite" />
      </div>
    </div>
    <!-- /End replace -->
  </slideover>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Slideover from "@/common/components/slideover";
import useUsersRepository from "@/composable/useUsersRepository";

import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import useRolesRepository from "../../../../composable/useRolesRepository";

import Invitees from "./_invitees.vue";

export default {
  components: {
    Slideover,
    Invitees,
    PaperAirplaneIcon,
  },

  setup(_, { emit }) {
    const store = useStore();
    const { invites, getInvites, sandInvite, deleteInvite } =
      useUsersRepository();
    const { loading, roles, getAllRoles } = useRolesRepository();
    const open = ref(true);

    const state = reactive({
      name: "",
      role: "",
    });

    const onInvite = () => {
      sandInvite(
        state.name,
        store.state.user.currentUser.Organization.Id,
        state.role
      );
      state.name = "";
      state.role = "";
    };

    const removeInvite = (id) => {
      deleteInvite(id);
    };

    const onClose = () => {
      emit("onClose", true);
    };

    onMounted(() => {
      getInvites();
      getAllRoles();
    });

    return {
      open,
      state,
      loading,
      roles,
      invites,
      onClose,
      onInvite,
      removeInvite,
    };
  },
};
</script>

<style scoped>
.bb {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 16px;
}
</style>
