import { ref } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";
import ErrorHandler from "@/services/errorHandler";

export default function useUsersRepository() {
  const users = ref([]);
  const invites = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getUsers = async (page, limit) => {
    loading.value = true;
    try {
      let result = await API.Organization.Users.get(page, limit);
      users.value = result.data.List;
      totalResults.value = result.data.TotalResults;
      loading.value = false;
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Users",
        type: "warning",
      });
    }
  };

  const getInvites = async () => {
    let result = await API.Organization.Users.invites();
    invites.value = result.data.List;
  };

  const sandInvite = async (email, orgId, roleId) => {
    try {
      let result = await API.Organization.Users.invite(email, orgId, roleId);

      if (result.data.Success) {
        invites.value.push(result.data.Item);
        ElNotification({
          title: "Success",
          message: "Invite sent successfully",
          type: "success",
        });
      }
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: new ErrorHandler(error).getMessage(),
        type: "warning",
      });
    }
  };

  const deleteInvite = async (id) => {
    try {
      let result = await API.Organization.Users.removeInvite(id);
      if (result.data.Success) {
        ElNotification({
          title: "Success",
          message: "Invite deleted successfully",
          type: "success",
        });

        getInvites();
      }
    } catch (error) {
      ElNotification({
        title: "Oops",
        message: new ErrorHandler(error).getMessage(),
        type: "warning",
      });
    }
  };

  const updateUser = async (user) => {
    let result = await API.Organization.Users.update(user);
    if (result.data.Success) {
      ElNotification({
        title: "Success",
        message: "User updated successfully",
        type: "success",
      });
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while updating user",
        type: "warning",
      });
    }
  };

  return {
    users,
    invites,
    loading,
    getUsers,
    updateUser,
    sandInvite,
    getInvites,
    deleteInvite,
    totalResults,
  };
}
