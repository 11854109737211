<template>
  <div class="overflow-hidden bg-white sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="invite in invitees" :key="invite.Id">
        <div class="block hover:bg-gray-50">
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="truncate text-sm font-medium text-indigo-600">
                {{ invite.Username }}
              </p>
              <div class="ml-2 flex flex-shrink-0">
                <p
                  v-if="invite.Status === 'Sent'"
                  class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800"
                >
                  {{ invite.Status }}
                </p>
                <p
                  v-if="invite.Status === 'Opened'"
                  class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                >
                  {{ invite.Status }}
                </p>
              </div>
              <button
                type="button"
                class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                @click="handleRemove(invite.Id)"
              >
                <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
              </button>
            </div>
            <div class="mt-2 sm:flex sm:justify-between">
              <div class="sm:flex">
                <p class="flex items-center text-sm text-gray-500">
                  <UsersIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ invite.Role }}
                </p>
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <CalendarIcon
                  class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  <time :datetime="invite.Created">{{
                    new Date(invite.Created).toLocaleDateString("en-US")
                  }}</time>
                </p>
              </div>
              <!-- Need this div for symmetry -->
              <div></div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <DeleteInviteModal
    :show="state.showDeleteModal"
    @onClose="onClose"
    @onSubmit="onSubmit"
  />
</template>

<script>
import { reactive } from "vue";
import { TrashIcon, CalendarIcon, UsersIcon } from "@heroicons/vue/outline";
import DeleteInviteModal from "./_deleteInvite";

export default {
  components: {
    TrashIcon,
    CalendarIcon,
    UsersIcon,
    DeleteInviteModal,
  },
  props: {
    invitees: {
      type: Array,
      default: () => [],
    },
  },
  setup(_, { emit }) {
    const state = reactive({
      showDeleteModal: false,
      id: null,
    });

    const handleRemove = (id) => {
      state.id = id;
      state.showDeleteModal = true;
    };

    const onClose = () => {
      state.showDeleteModal = false;
    };

    const onSubmit = () => {
      emit("onSubmit", state.id);
      state.showDeleteModal = false;
    };

    return {
      state,
      onClose,
      onSubmit,
      handleRemove,
    };
  },
};
</script>

<style lang="scss" scoped></style>
