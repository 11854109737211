<template>
  <div v-loading="loading" class="layout">
    <!-- <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Team</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_candidate" class="sr-only">Search</label>
        </div>
        <button
          type="button"
          @click="openInviteDrawer"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Invite
        </button>
      </div>
    </div> -->
    <Title name="Team" />

    <div class="flex justify-between">
      <Search />
      <div class="flex justify-end">
        <button
          type="button"
          @click="openInviteDrawer"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Manage Invites
        </button>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="mt-8 overflow-hidden border border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Roles
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="users.length > 0"
              >
                <tr v-for="user in users" :key="user.Parent.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          v-if="user.Parent.Picture"
                          class="h-10 w-10 rounded-full"
                          :src="`data:image/png;base64, ${user.Parent.Picture}`"
                          alt=""
                        />
                        <svg
                          v-else
                          width="38"
                          height="38"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            color="text-gray-400"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ user.Parent.FullName }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ user.Parent.Username }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                  >
                    <div v-if="user.OrganizationRoles.length > 0">
                      <span
                        v-if="user.OrganizationRoles[0] !== undefined"
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >
                        {{ user.OrganizationRoles[0].Name }}
                      </span>
                      <span
                        v-if="user.OrganizationRoles.length > 1"
                        class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >
                        + {{ user.OrganizationRoles.length - 1 }}
                      </span>
                    </div>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="onEdit(user)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Delete',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="openModal(user.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-red-300 px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                    >
                      <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <InviteUser
    :show="state.toggleInvite"
    @onClose="closeInvite"
    @delete="handleDelete"
  />
  <EditUser
    v-if="Object.entries(state.editForm).length > 0"
    :show="state.toggleEdit"
    @onClose="closeEdit"
    :user="state.editForm"
  />
  <DeleteModal
    :show="state.toggleModal"
    @onClose="closeModal"
    @onSubmit="onDelete"
  />
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";

import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";
import Title from "@/common/components/title";
import Search from "@/common/components/search";
import Pagination from "@/common/components/pagination";
import InviteUser from "./components/_invite.vue";
import EditUser from "./components/_edit.vue";
import DeleteModal from "./components/_deleteModal";

import API from "@/api";
import useUsersRepository from "@/composable/useUsersRepository";

// import _ from "lodash";
import dayjs from "dayjs";
import { directive } from "vue-tippy";

import "tippy.js/animations/scale.css";

export default defineComponent({
  components: {
    Title,
    Search,
    Pagination,
    TrashIcon,
    PencilIcon,
    InviteUser,
    EditUser,
    DeleteModal,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const { loading, totalResults, users, getUsers, deleteUser } =
      useUsersRepository();
    const state = reactive({
      toggleInvite: false,
      toggleEdit: false,
      toggleModal: false,
      editForm: {},
      selectedUserId: "",
    });

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      search: "",
      totalResults: totalResults,
    });

    const closeInvite = () => {
      state.toggleInvite = false;
    };

    const closeEdit = () => {
      state.toggleEdit = false;
    };

    const onEdit = (user) => {
      state.toggleEdit = true;
      state.editForm = {};
      state.editForm = user;
    };

    const closeModal = () => {
      state.openModal = false;
    };

    const openModal = (id) => {
      state.openModal = true;
      state.selectedUserId = id;
    };

    const onDelete = () => {
      deleteUser(state.selectedUserId);
      closeModal();
    };

    onMounted(() => {
      getUsers(pagination.page, pagination.pageSize);
    });

    return {
      state,
      users,
      onEdit,
      loading,
      pagination,
      closeModal,
      openModal,
      closeInvite,
      closeEdit,
      onDelete,
    };
  },
  created() {
    // this.fetchUsers();
    // this.fetchRoles();
    //this.debouncedGetAnswer = _.debounce(this.getAnswer, 1000);
    //this.fetchNotifications();
  },
  methods: {
    //getAnswer() {
    // let results = await API.Admin.Users.search(
    //     this.pagination.page,
    //     this.pagination.pageSize
    //   );
    //   this.users = results.data.List;
    //   this.pagination.page = results.data.Page;
    //   this.pagination.pageSize = results.data.PageSize;
    //   this.pagination.totalResults = results.data.TotalResults;
    //},
    // async fetchUsers() {
    //   try {
    //     this.loading = true;
    //     let results = await API.Organization.Users.get(
    //       this.pagination.page,
    //       this.pagination.pageSize
    //     );
    //     console.log({ results });
    //     this.users = results.data.List;

    //     this.pagination.page = results.data.Page;
    //     this.pagination.pageSize = results.data.PageSize;
    //     this.pagination.totalResults = results.data.TotalResults;

    //     this.loading = false;
    //   } catch (error) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: `Problem while fetching users. ${error}`,
    //     });
    //     this.loading = false;
    //   }
    // },
    // async fetchRoles() {
    //   try {
    //     let results = await API.Admin.Roles.all();
    //     this.roles = results.data.List;
    //   } catch (error) {
    //     this.$notify.error({
    //       title: "Error",
    //       message: `Problem while fetching roles. ${error}`,
    //     });
    //   }
    // },

    async fetchInvites() {
      let result = await API.Organization.Users.invites();
      this.invites = result.data.List;
    },

    async fetchNotifications() {
      try {
        let results = await API.Organization.Notifications.filter(4, 1);
        console.log({ results });
        this.notifications = results.data.List;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Error while fetching notifications. ${error}`,
        });
      }
    },

    onSubmit(createForm) {
      console.log({ createForm });
      this.$refs[createForm].validate((valid) => {
        if (valid) {
          try {
            API.Admin.Users.save(this.createForm).then(() => {
              this.drawer.create = false;

              this.$notify({
                title: "Success",
                message: "New user saved",
                type: "success",
              });

              this.fetchUsers();
            });
          } catch (error) {
            this.$notify.error({
              title: "Error",
              message: `Error while saving new user. ${error}`,
            });
          }
        } else {
          //this.resetForm();
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    onUpdate() {
      try {
        this.editForm.id = this.selectedUser.Id;
        let sysRoles = [];
        this.editForm.systemRoles.forEach((id) => {
          sysRoles.push({
            id: id,
          });
        });

        this.editForm.systemRoles = sysRoles;

        API.Admin.Users.update(this.editForm).then(() => {
          this.drawer.edit = false;

          this.$notify({
            title: "Success",
            message: "User updated",
            type: "success",
          });

          this.fetchUsers();
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Error while updating user. ${error}`,
        });
      }
    },

    onChangePage(page) {
      this.pagination.page = page;

      this.fetchUsers();
    },

    async fetchUser(item) {
      let results = await API.Admin.Users.getBy(item);

      this.selectedUser = results.data.Item;

      this.editForm.firstName = this.selectedUser.FirstName;
      this.editForm.lastName = this.selectedUser.LastName;
      this.editForm.middleName = this.selectedUser.MiddleName;
      this.editForm.username = this.selectedUser.Username;

      this.selectedUser.SystemRoles.forEach((role) => {
        this.editForm.systemRoles.push(role.Id);
      });
    },

    handleEdit(item) {
      console.log({ item });
      this.drawer.edit = true;
      this.drawer.loading = true;
      try {
        this.fetchUser(item);
        this.drawer.loading = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Problem while fetching users. ${error}`,
        });
        this.loading = false;
      }
    },

    openInviteDrawer() {
      //this.fetchInvites();
      this.state.toggleInvite = true;
    },

    onInvite() {
      if (this.inviteForm.email === "") {
        this.$notify.info({
          title: "Info",
          message: "Please type email of person you wish to invite.",
        });
        return;
      }

      API.Organization.Users.invite(
        this.inviteForm.email,
        this.$store.state.user.currentUser.Organization.Id
      )
        .then(() => {
          this.fetchInvites();
        })
        .catch((error) => {
          this.$notify.info({
            title: "Info",
            message: error.response.data.Message,
          });
        });
    },

    formatDate(val) {
      return dayjs(val).format("DD/MM/YYYY");
    },

    goToStripe() {
      console.log("ff", this.notifications[0].Description);
      window.open(`${this.notifications[0].Description}`, "_blank");
    },
  },
  watch: {
    search: function (val) {
      if (val.length > 0) {
        this.debouncedGetAnswer();
      }
    },
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
.p-8 {
  padding: 32px;
}
</style>
